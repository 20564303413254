export function element(el: string | HTMLScriptElement): Element {
  return typeof el === 'string' ? document.querySelector(el) : el;
}

export function addClass(element: Element, className: string) {
  element.className += ' ' + className;
}

export function removeClass(element: Element, className: string) {
  element.className = element.className
    .replace(new RegExp('( |^)' + className + '( |$)', 'g'), ' ')
    .trim();
}
