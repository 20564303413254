export interface Script {
  src: string;
  attrs?: [string[]]
}

export default function(scripts: Script[]) {
  scripts.forEach(({src, attrs}) => {
    const el = document.createElement('script');
    el.async = false;
    if(attrs) {
      attrs.forEach(attr => {
        el.setAttribute(attr[0], attr[1]);
      });
    }
    el.src = src;
    document.head.appendChild(el);
  });
}
