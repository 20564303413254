import { detect } from 'detect-browser';

// specific browsers not supported
export const browsersNotSupported = {
  ie: [10, 9, 8, 7],
  edge: [44]
};

const browser = detect();
export const detectedBrowser = browser;

export default function isBrowserSupported() {
  const b = browsersNotSupported[browser.name];
  if (!b) {
    // if the browser is not in the browsersNotSupported map, assume it is supported
    return true;
  }

  const majorNumber = +browser.version.split('.')[0];
  // don't use includes or indexOf here to support older browsers
  let isSupported = true;
  for (let i = 0; i < b.length; i++) {
    if (majorNumber === b[i]) {
      isSupported = false;
    }
  }
  return isSupported;
}
