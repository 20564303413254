import 'current-script-polyfill';
import 'element-closest-polyfill';
import './loader.scss';
import insertScripts from './script-loader';
// import { uid } from '../ClientApp/src/app/common/utils/uid';
import isBrowserSupported, { detectedBrowser } from './browsers';
import { element, addClass, removeClass } from './utils';
// @ts-ignore
import html from './loader.html';

const isDigitalOceanSite = (window as any).__digital_ocean_site__;
const baseUrl = (document.currentScript as HTMLScriptElement).src.replace('loader.js', '');

function loadAssets() {
  if (baseUrl.indexOf('localhost') === -1) {
    insertScripts([
      { src: baseUrl + 'runtime.js' },
      { src: baseUrl + 'polyfills-es5.js', attrs: [['nomodule', '']] },
      { src: baseUrl + 'polyfills.js' },
      { src: baseUrl + 'main.js' }
    ]);
  } else {
    insertScripts([
      { src: baseUrl + 'runtime.js' },
      { src: baseUrl + 'polyfills-es5.js', attrs: [['nomodule', '']] },
      { src: baseUrl + 'polyfills.js' },
      { src: baseUrl + 'vendor.js' },
      { src: baseUrl + 'main.js' }
    ]);
  }
  // style is already loaded by the digital ocean website
  if (!isDigitalOceanSite) {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = baseUrl + 'styles.css';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

const currentScript = document.currentScript as HTMLScriptElement;
const noop = () => { }
let isSupported = isBrowserSupported();
if (isSupported) {
  loadAssets();
}

if (!isSupported && isDigitalOceanSite) {
  // if browser is not supported and we are on the digital ocean website, alert
  alert('This browser is not supported');
}

export default {
  async create({ type, id, el, config, url }) {
    const container = document.createElement('div');
    addClass(container, '_do_');
    addClass(container, 'digital-ocean-embed-container');
    // container.setAttribute('id', uid());

    const insertContainer = () => {
      // insert embed into named element or beside current script tag
      if (el) {
        element(el).appendChild(container);
      } else {
        currentScript.parentNode.insertBefore(container, currentScript.nextSibling);
      }
    }

    // once IE11 is fully not supported, remove this if statement and add 11 to browsersNotSupported array
    if (detectedBrowser.name === 'ie'
      && id !== 1
      && id !== 'habs') {
      isSupported = false;
    }

    if (!isSupported) {
      addClass(container, 'digital-ocean-browser-not-supported');
      if (id == 5 || id == 'pirs') {
        container.innerHTML = 'This table cannot be displayed as this browser is no longer supported. To view this table, please update your browser or switch to another.'
      }
      else {
        container.innerHTML = 'This browser does not support embedding Digital Ocean widgets';
      }
      insertContainer();
      return;
    }

    addClass(container, 'digital-ocean-embed-is-loading');
    this.addLoader({
      el: container,
      message: 'Loading...'
    });

    const embed = document.createElement('digital-ocean-embed');

    if (url) {
      await fetch(url).then(res => res.json()).then(res => {
        if (res.type) type = res.type;
        if (res.id) id = res.id;
        if (res.config) config = res.config;
      });
    }

    if (type) {
      embed.setAttribute('type', type);
    }
    if (id) {
      embed.setAttribute('id', id);
    }
    if (config) {
      (embed as any).config = config;
    }

    console.log("create() appendchild",embed);
    container.appendChild(embed);

    insertContainer();
  },
  addLoader({ el, message = '' }) {
    const _html = html.replace('$message', message);
    element(el).insertAdjacentHTML('beforeend', _html);
  },
  removeLoader({ el, delay = 100, done = noop }) {
    let loader = element(el).querySelector('.digital-ocean-loader');
    if (!loader) {
      loader = el.parentNode.querySelector('.digital-ocean-loader');
    }

    if (!loader) {
      return;
    }

    const container = loader.closest('.digital-ocean-embed-container');

    setTimeout(() => {
      addClass(loader, 'digital-ocean-loader-remove');
      setTimeout(() => {
        if (loader.parentNode) {
          loader.parentNode.removeChild(loader);
        }
        removeClass(container, 'digital-ocean-embed-is-loading');
        done();
      }, 300);
    }, delay);
  }
}
